function right() {
  return (
    <div className="right 900px:ml-0 ml-[7vw] z-50">
      <h1 className="text-[3.6vh]">Ben Kimim ?</h1>
      <p className="max-w-[60vh] 900px:max-w-full text-[2.1vh] mt-[2.5vh]">
        Merhaba! Ben Eva. Twitch'te yayın yapıyorum ve izleyicilerimle samimi
        sohbetlere, oyunlara ve güncel konulara yer veriyorum. 99 doğumluyum ve
        her yayında izleyicilerimle bağ kurmayı, onların katılımını önemsiyorum.
      </p>
      <p className="max-w-[60vh] 900px:max-w-full text-[2.1vh] mt-[2.5vh]">
        Yayınlarım, sadece oyunlara değil, günlük yaşama dair konuları da
        kapsıyor. Her izleyiciyi önemsiyor, katılan herkesin kendini özel
        hissetmesi için çaba gösteriyorum. Yeni insanlarla tanışmak, farklı
        perspektifler keşfetmek ve keyifli zaman geçirmek benim için büyük bir
        mutluluk kaynağı.
      </p>
    </div>
  );
}

export default right;
