import React from "react";

function right() {
  return (
    <div className="right ">
      <p className="cursor-pointer 600px:hidden text-[2.1vh]">
        eva.dulkr@gmail.com{" "}
      </p>
    </div>
  );
}

export default right;
