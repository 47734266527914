import List from "./list";

function navbar() {
  return (
    <div className="navbar flex justify-center items-center w-full p-[32px] 600px:p-[16px]">
      <List></List>
    </div>
  );
}

export default navbar;
