import React from "react";

function img() {
  return (
    <a href="https://www.twitch.tv/evaizm" target="_blank">
      <img
        id="evaizm-img"
        src="//i.ibb.co/tmsVPyB/evaizm-main-1.webp"
        alt="evaizm-img"
        className="h-[40vh] w-[40vh] object-cover rounded-full cursor-pointer hover:saturate-0 duration-100 600px:h-[200px] 600px:w-[200px] 420px:h-[165px] 420px:w-[165px]"
      />
    </a>
  );
}

export default img;
