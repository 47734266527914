import React from "react";

function right() {
  return (
    <div className="right 900px:hidden ml-[7vw]">
      <img
        src="https://i.ibb.co/d6k5ybt/eva-mail-1.webp"
        className="h-[88vh] rounded-[16px] object-cover"
        alt="eva-biography-img"
        id="email-img"
      />
    </div>
  );
}

export default right;
