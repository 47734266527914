import React from "react";

function left() {
  return (
    <div className="left 900px:hidden">
      <img
        src="https://i.ibb.co/wYVt4hB/eva-bio-1.webp"
        className="h-[88vh] rounded-[16px] object-cover cursor-pointer"
        id="biography-img"
        alt="evaizm-img"
      />
    </div>
  );
}

export default left;
