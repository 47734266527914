import React from "react";

function shadowRight() {
  return (
    <div
      id="shadow-right"
      className="shadow-right absolute bg-black h-[25vw] w-[25vw] right-0 top-0"
    ></div>
  );
}

export default shadowRight;
